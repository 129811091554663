import { Injectable, StateKey, makeStateKey } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Product } from './offer.model';

export const PRODUCT_KEY: StateKey<Product> = makeStateKey<Product>('product');

@Injectable({
	providedIn: 'root',
})
export class OfferGuard {
	canActivate(
		route: ActivatedRouteSnapshot,
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const id = parseInt(route.paramMap.get('id'));
		if (!id || isNaN(id)) {
			return false;
		}

		return true;
	}
}
