<button
	(keyup.escape)="dialog.close()"
	(click)="dialog.close()"
	class="absolute text-2xl right-4 top-2 text-mainColor"
	aria-label="Zamknij"
>
	<i class="fas fa-times"></i>
</button>
<form *ngIf="!formSend" [formGroup]="contactForm" class="text-mainColor flex flex-col py-6 md:px-20">
	<h1 class="uppercase font-extrabold text-xl text-center pb-4">Formularz kontaktowy</h1>
	<div class="contact-full">
		<label class="contact-label" for="firstName">Imię<span class="text-red ml-1">*</span></label>
		<div class="relative">
			<input
				class="contact-input"
				type="text"
				formControlName="firstName"
				name="firstName"
				id="firstName"
				placeholder="Imię"
				[ngClass]="{
					'!border-red': contactForm.get('firstName')?.invalid && contactForm.get('firstName')?.touched,
				}"
			/>
			<small
				[ngClass]="{
					'error-label-visible':
						contactForm.get('firstName')?.invalid && contactForm.get('firstName')?.touched,
				}"
				class="error-label"
				>Imię jest wymagane</small
			>
		</div>
	</div>
	<div class="contact-full">
		<label class="contact-label" for="lastName">Nazwisko</label>
		<div class="relative">
			<input
				class="contact-input"
				type="text"
				formControlName="lastName"
				name="lastName"
				id="lastName"
				placeholder="Nazwisko"
				[ngClass]="{
					'!border-red': contactForm.get('lastName')?.invalid && contactForm.get('lastName')?.touched,
				}"
			/>
		</div>
	</div>
	<div class="contact-full">
		<label class="contact-label" for="email">Email</label>
		<div class="relative">
			<input
				class="contact-input"
				type="email"
				formControlName="email"
				name="email"
				id="email"
				placeholder="Email"
				[ngClass]="{
					'!border-red': contactForm.get('email')?.invalid && contactForm.get('email')?.touched,
				}"
			/>
			<small
				[ngClass]="{
					'error-label-visible': contactForm.get('email')?.invalid && contactForm.get('email')?.touched,
				}"
				class="error-label"
			>
				Niepoprawny adres email
			</small>
		</div>
	</div>
	<div class="contact-full">
		<label class="contact-label" for="phone">Telefon<span class="text-red ml-1">*</span></label>
		<div class="relative">
			<input
				class="contact-input"
				type="tel"
				name="phone"
				formControlName="phone"
				id="phone"
				placeholder="Numer telefonu"
				[ngClass]="{
					'!border-red': contactForm.get('phone')?.invalid && contactForm.get('phone')?.touched,
				}"
			/>
			<small
				[ngClass]="{
					'error-label-visible': contactForm.get('phone')?.invalid && contactForm.get('phone')?.touched,
				}"
				class="error-label"
			>
				{{
					contactForm.get('phone')?.hasError('required')
						? 'Telefon jest wymagany'
						: 'Nieprawidłowy numer telefonu'
				}}
			</small>
		</div>
	</div>
	<!--	hidden for now-->
	<!--	<div class="contact-full">-->
	<!--		<label class="contact-label" for="phone"-->
	<!--			>Temat<span class="text-red ml-1">*</span></label-->
	<!--		>-->
	<!--		<div class="relative">-->
	<!--			<app-custom-select-->
	<!--				(outputSelectedOption)="getValueFromChild($event)"-->
	<!--				[options]="['opcja 1: informacje odnosnie rezerwacji']"-->
	<!--				[tabindex]="0"-->
	<!--			></app-custom-select>-->
	<!--		</div>-->
	<!--	</div>-->

	<div class="contact-full !items-start">
		<label class="contact-label mt-2" for="phone">Wiadomość</label>
		<div class="relative">
			<textarea
				name="message"
				formControlName="message"
				id="message"
				class="contact-input resize-none"
				placeholder="Twoja wiadomość"
				cols="30"
				rows="10"
				[ngClass]="{
					'!border-red': contactForm.get('message')?.invalid && contactForm.get('message')?.touched,
				}"
			></textarea>
			<small
				[ngClass]="{
					'error-label-visible': contactForm.get('message')?.invalid && contactForm.get('message')?.touched,
				}"
				class="error-label"
				>Uzupełnij treść wiadomości</small
			>
		</div>
	</div>
	<div class="inline-flex w-80 md:w-auto items-center self-center">
		<div class="relative">
			<input
				class="accent-accentColor relative z-50 hover:accent-mainColor cursor-pointer h-4 w-4 transtition mr-3"
				formControlName="consentCheck"
				type="checkbox"
				name="contactFormConsent"
				id="contactFormConsent"
			/>
			<div
				[ngClass]="{
					'!opacity-100':
						contactForm.get('consentCheck')?.invalid && contactForm.get('message')?.touched && showError,
				}"
				class="consent-error opacity-0"
			>
				<i class="fas fa-exclamation-circle mr-1"></i>
				Wymagana zgoda
			</div>
		</div>
		<div class="relative md:w-[26rem] text-justify leading-4">
			<label for="contactFormConsent" class="text-xs">
				Administratorem danych osobowych jest Biuro Podróży Oskar sp. z o.o. z siedzibą w Poznaniu (61-738),
				przy ul. Plac Wolności 2/4c, KRS: 0000701992, podane dane w formularzu kontakowym będą przetwarzane w
				celu udzielenia odpowiedzi na przesłane zapytanie zgodnie z
				<a class="hover:underline" href="https://oskar.com.pl/informacje/politycy-privacy" target="_blank"
					><b>polityką prywatności.</b></a
				><span class="text-red">*</span>
			</label>
			<p class="text-xs absolute bottom-[-20px] left-0"><span class="text-red">*</span> - wymagane</p>
		</div>
	</div>
	<div class="pt-4 flex md:w-auto w-80 self-center md:self-end justify-end">
		<app-button (click)="submitForm()" class="w-fit" type="footer">Wyślij</app-button>
	</div>
</form>
<div *ngIf="formSend" class="text-mainColor h-full justify-center px-16 py-5 flex flex-col items-center">
	<h1 class="text-mainYellow font-extrabold text-2xl pb-5">Dziękujemy</h1>
	<p class="w-80 text-center pb-5">Wiadmość została wysłana pomyślnie!</p>
	<app-button (click)="dialog.close()" type="box-button-added">Zamknij</app-button>
</div>
